/* @license
 * Copyright 2020 Google LLC. All Rights Reserved.
 * Licensed under the Apache License, Version 2.0 (the 'License');
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an 'AS IS' BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * Adapted from WorkerDOM
 * @see https://github.com/ampproject/worker-dom/blob/master/src/worker-thread/index.amp.ts
 */
export const ALLOWLISTED_GLOBALS: {[key: string]: boolean} = {
  Array: true,
  ArrayBuffer: true,
  BigInt: true,
  BigInt64Array: true,
  BigUint64Array: true,
  Boolean: true,
  Cache: true,
  CustomEvent: true,
  DataView: true,
  Date: true,
  Error: true,
  EvalError: true,
  Event: true,
  EventTarget: true,
  Float32Array: true,
  Float64Array: true,
  Function: true,
  Infinity: true,
  Int16Array: true,
  Int32Array: true,
  Int8Array: true,
  Intl: true,
  JSON: true,
  Map: true,
  Math: true,
  MessagePort: true,
  MessageEvent: true,
  MessageChannel: true,
  NaN: true,
  Number: true,
  Object: true,
  Promise: true,
  Proxy: true,
  RangeError: true,
  ReferenceError: true,
  Reflect: true,
  RegExp: true,
  Set: true,
  String: true,
  Symbol: true,
  SyntaxError: true,
  TextDecoder: true,
  TextEncoder: true,
  TypeError: true,
  URIError: true,
  URL: true,
  Uint16Array: true,
  Uint32Array: true,
  Uint8Array: true,
  Uint8ClampedArray: true,
  WeakMap: true,
  WeakSet: true,
  WebAssembly: true,
  atob: true,
  addEventListener: true,
  removeEventListener: true,
  btoa: true,
  caches: true,
  clearInterval: true,
  clearTimeout: true,
  console: true,
  decodeURI: true,
  decodeURIComponent: true,
  document: true,
  encodeURI: true,
  encodeURIComponent: true,
  escape: true,
  fetch: true,
  indexedDB: true,
  isFinite: true,
  isNaN: true,
  location: true,
  navigator: true,
  onerror: true,
  onrejectionhandled: true,
  onunhandledrejection: true,
  parseFloat: true,
  parseInt: true,
  performance: true,
  postMessage: true,
  requestAnimationFrame: true,
  cancelAnimationFrame: true,
  self: true,
  setTimeout: true,
  setInterval: true,
  unescape: true,
};
